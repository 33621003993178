<template>

  <div>
    <Header></Header>
    <div>
      <ul>
        <li
          class="g1"
          id="g_1"
        >
          <a href="#a1">
            <el-tooltip
              class="item"
              effect="light"
              content="游客服务"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter1"
                  class="side side1"
                  @mouseover="changeIndex1"
                  @mouseout="changeIndexBack1"
                >
                <img
                  src='./bwg_img/pic7.png'
                  class="side "
                  v-show="side1_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g2"
          id="g_2"
        >
          <a href="#a2">
            <el-tooltip
              class="item"
              effect="light"
              content="馆藏业务"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter2"
                  class="side side2"
                  @mouseover="changeIndex2"
                  @mouseout="changeIndexBack2"
                >
                <img
                  src="./bwg_img/pic11.png"
                  class="side "
                  v-show="side2_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g3"
          id="g_3"
        >
          <a href="#a3">
            <el-tooltip
              class="item"
              effect="light"
              content="博物馆营销"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter3"
                  class="side side3"
                  @mouseover="changeIndex3"
                  @mouseout="changeIndexBack3"
                >
                <img
                  src="./bwg_img/pic10.png"
                  class="side side3_1"
                  v-show="side3_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g4"
          id="g_4"
        >
          <a href="#a4">
            <el-tooltip
              class="item"
              effect="light"
              content="文物监测预警系统"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter4"
                  class="side side4"
                  @mouseover="changeIndex4"
                  @mouseout="changeIndexBack4"
                >
                <img
                  src="./bwg_img/pic12.png"
                  class="side side4_1"
                  v-show="side4_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g5"
          id="g_5"
        >
          <a href="#a5">
            <el-tooltip
              class="item"
              effect="light"
              content="智能监控"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter5"
                  class="side side5"
                  @mouseover="changeIndex5"
                  @mouseout="changeIndexBack5"
                >
                <img
                  src="./bwg_img/pic23.png"
                  class="side side5_1"
                  v-show="side5_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g6"
          id="g_6"
        >
          <a href="#a6">
            <el-tooltip
              class="item"
              effect="light"
              content="电子巡更"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter6"
                  class="side side6"
                  @mouseover="changeIndex6"
                  @mouseout="changeIndexBack6"
                >
                <img
                  src="./bwg_img/pic24.png"
                  class="side side6_1"
                  v-show="side6_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g7"
          id="g_7"
        >
          <a href="#a7">
            <el-tooltip
              class="item"
              effect="light"
              content="智能分析应用"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter7"
                  class="side side7"
                  @mouseover="changeIndex7"
                  @mouseout="changeIndexBack7"
                >
                <img
                  src="./bwg_img/pic8.png"
                  class="side side7_1"
                  v-show="side7_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g9"
          id="g_9"
        >
          <a href="#a9">
            <el-tooltip
              class="item"
              effect="light"
              content="数字资源"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter8"
                  class="side side9"
                  @mouseover="changeIndex8"
                  @mouseout="changeIndexBack8"
                >
                <img
                  src="./bwg_img/pic9.png"
                  class="side side9_1"
                  v-show="side9_1"
                >
              </div>
            </el-tooltip>
          </a>
        </li>
        <li
          class="g8"
          id="g_8"
        >
          <a href="#a8">
            <el-tooltip
              class="item"
              effect="light"
              content="回到顶部"
              placement="left"
            >
              <div class="W2_2">
                <img
                  :src="indexCenter9"
                  class="side"
                  @mouseover="changeIndex9"
                  @mouseout="changeIndexBack9"
                >
              </div>
            </el-tooltip>
          </a>
        </li>

      </ul>

      <!-- <div id="a8" class="a_8" v-lazy:background-image="require('./bwg_img/img 36.png')"  -->

      <div
        id="a8"
        class="a_8"
      >
        <!-- <img src="./bwg_img/img 36.png" class="a_8"> -->
        <div class="start">
          <img
            v-lazy='require("./bwg_img/b7X0e5AX.webp")'
            class="start_img"
          >
        </div>
        <div class="bannert">
          <div class="headers">智慧博物馆</div>
          <div class="header2">运用最新的互连网、物联网技术，结合专业指向的内容和文化的挖掘包装，丰富展陈方式，
            扩展展陈内容，从而更好地承担社会教育和文化传播责任，加强博物馆服务、保护和管理工作的智能化协同</div>
        </div>
      </div>
      <div
        id="a1"
        class="a_1"
      >
        <div class="info11">游客服务</div>
        <div class="word7">通过各种设施、设备、方法、途径，使旅客在接受服务的过程中产生惬意、幸福之感</div>
        <div class="main">
          <div class="img_1">
            <div class="load-img">
              <img
                v-lazy="require('./bwg_img/2G5NpL19.webp')"
                class="img_2_2"
              >
            </div>
            <span class="txt2">虚拟博物馆</span>
            <span class="word8"> 结合了虚拟现实技术等多种高科技手段，在线观看畅游博物馆，体验互联网3D时代颠覆传统B2B模式</span>
            <span
              class="txt3"
              @click="jump('/virtual_museum')"
            >了解详情&nbsp;&gt;</span>
          </div>
          <div class="img_2 ml">
            <div class="load-img">
              <img
                v-lazy="require('./bwg_img/gX89tpm6.webp')"
                class="img_2_2"
              >
            </div>
            <span class="info1">AR扫码文物卡片</span>
            <span class="word8"> 通过对卡片图像扫码，把文物面貌直接的融入到周围的环境中，从而达到超越现实的感官体验</span>
            <span
              class="txt3"
              @click="jump('/cultural_relic')"
            >了解详情&nbsp;&gt;</span>
          </div>
          <div class="img_3 ml">
            <div class="load-img">
              <img
                v-lazy="require('./bwg_img/B1pic8vE.webp')"
                class="img_2_2"
              >
            </div>

            <span class="txt2">电子地图</span>
            <span class="word8"> 展示景区内外3D电子地图，实现跨楼层/楼宇导航，导航过程带有语音、距离提示</span>
            <span class="word11">敬请期待...</span>
          </div>
        </div>
        <div class="main">
          <div class="img_1">
            <div class="load-img">
              <img
                v-lazy="require('./bwg_img/ntIrBbEo.webp')"
                class="img_2_2"
              >
            </div>

            <span class="txt2">多媒体讲解</span>
            <span class="word8"> 系统会自动将游客周边的展品信息推送到手机上，游客可通过文字、语音以及视频信息对展品进行了解</span>
            <span class="word11">敬请期待...</span>
          </div>
          <div class="img_2 ml">
            <div class="load-img">
              <img
                v-lazy="require('./bwg_img/SiYEjq6v.webp')"
                class="img_2_2"
              >
            </div>

            <span class="info1">三维可视化</span>
            <span class="word8"> 以领先的3D技术为文物提供数字化保护，助力文博艺术行业实现智能化，打造信息化、高效化的智慧文博</span>
            <span class="word11">敬请期待...</span>
          </div>
          <div class="img_3 ml">
            <div class="load-img">
              <img
                v-lazy="require('./bwg_img/deGPNqQ4.webp')"
                class="img_2_2"
              >
            </div>

            <span class="txt2">导览屏</span>
            <span class="word8"> 以数字大屏为基础，结合导航技术通过图片、音频、视频对场馆进行360°展示</span>
            <span class="word11">敬请期待...</span>
          </div>
        </div>

      </div>
      <!-- <div id="a2" class="a_2" v-lazy:background-image="require('./bwg_img/img 21.png')"> -->
      <div
        id="a2"
        class="a_2"
      >
        <div class="img-2">
          <img
            v-lazy='require("./bwg_img/RnoOPNGg.webp")'
            class="img-3"
          >
        </div>
        <div class="lintere2">
          <div class="info12">馆藏业务</div>
          <div class="word19">适配全馆馆藏业务的解决方案，提供藏品业务、展览业务、宣传业务、档案业务等</div>
          <div class="section10 ">
            <img
              class="label1"
              v-lazy="require('./bwg_img/ICON 8 .png') "
            />
            <span class="txt10">鉴定管理</span>
            <img
              class="pic2"
              v-lazy="require('./bwg_img/ICON(1).png')"
            />
            <span class="txt10">库房管理</span>
            <img
              class="pic2"
              v-lazy="require('./bwg_img/ICON(2).png')"
            />
            <span class="txt10">藏品管理</span>
            <img
              class="pic2"
              v-lazy="require('./bwg_img/ICON(3).png')"
            />
            <span class="txt10">展览管理</span>
            <img
              class="pic2"
              v-lazy="require('./bwg_img/ICON(4).png')"
            />
            <span class="txt10">修复管理</span>
          </div>
        </div>
      </div>
      <div
        id="a3"
        class="a_3"
      >
        <div class="info13">博物馆营销</div>
        <div class="word7">探索打通线上线下融合的营销渠道,推动博物馆文创更精准、更灵活、更便捷地“飞入寻常百姓家”</div>
        <div class="a_min_3">
          <div class="load-2">
            <img
              v-lazy="require('./bwg_img/axQD4DV5.webp')"
              class="a_min_3_3"
            >
          </div>
          <div class="load-2">
            <img
              v-lazy="require('./bwg_img/MFS8WwAd.webp')"
              class="a_min_3_4"
            >
          </div>
        </div>
        <div class="txt20">
          <span
            class="word20"
            @click="jump('/museum_details')"
          >查看详情</span>
        </div>
      </div>
      <!-- <div id="a4" class="a_4" v-lazy:background-image="require('./bwg_img/img 13 (2).png')"> -->
      <div
        id="a4"
        class="a_4"
      >
        <div class="img-2">
          <img
            v-lazy='require("./bwg_img/yYzZOWTV.webp")'
            class="img-3"
          >
        </div>
        <div class="lintere8">
          <div class="info4">文物监测预警系统</div>
          <div class="word19">智能监测预警平台，实现文物保护的可视互联，解决文物安全、管理、研究、展示、利用、共享等方面的需求</div>
          <div class="txt4">
            <span
              class="word4"
              @click="jump('/Cultural_relic_monitoring_and_early_warning')"
            >查看详情</span>
          </div>
        </div>
      </div>
      <div
        id="a5"
        class="a_5"
      >
        <div class="info14">智能监控</div>
        <div class="word10">互联、互通、共享、智慧</div>
        <div class="main_2">
          <div class="img_5">

            <div class="img-5">
              <img
                v-lazy="require('./bwg_img/y9gdiZJM.webp')"
                class="pic6"
              >
            </div>
            <span class="txt_5">互联</span>
            <span class="txt_5_5"> 覆盖整个博物馆的高清视频监控统一一个平台集中管理，平台兼容市面主流监控品牌且支持标准协议监控设备</span>
          </div>
          <div class="img_5_1">
            <div class="img-5">
              <img
                v-lazy="require('./bwg_img/B0N5yabq.webp')"
                class="pic6"
              >
            </div>
            <span class="txt_5">互通</span>
            <span class="txt_5_5"> 博物馆各种网络情况下（专网、互联网、物联网、3G\4G）的资源均能统一集中到监控中心进行管控</span>
          </div>
          <div class="img_5_1">
            <div class="img-5">
              <img
                v-lazy="require('./bwg_img/XgOgp32m.webp')"
                class="pic6"
              >
            </div>

            <span class="txt_5">共享</span>
            <span class="txt_5_5"> 支持PC、移动终端、大屏、笔记本等方式进行数据查看，同时支持接口供其他第三方子系统的集成应用</span>
          </div>
          <div class="img_5_1">
            <div class="img-5">
              <img
                v-lazy="require('./bwg_img/zq4DdWYS.webp')"
                class="pic6"
              >
            </div>

            <span class="txt_5">智慧</span>
            <span class="txt_5_5"> 支持多种智能分析算法应用，由传统的“被动监控”到“主动防御”的转变</span>
          </div>
        </div>
      </div>
      <!-- <div id="a6" class="a_6" v-lazy:background-image="require('./bwg_img/img 22.png')"> -->
      <div
        id="a6"
        class="a_6"
      >
        <div class="img-2">
          <img
            v-lazy='require("./bwg_img/lnX2hBnq.webp")'
            class="img-3"
          >
        </div>
        <div class="lintere3">
          <div class="info12">电子巡更</div>
          <div class="word19">博物馆场地情况及管理要求，提供基于智能设备的巡更一体解决方案 </div>
          <div class="txt4">
            <span
              class="word4"
              @click="jump('/film_xun')"
            >查看详情</span>
          </div>
        </div>

      </div>
      <div
        id="a7"
        class="a_7"
      >
        <div class="info13">智能分析应用</div>
        <div class="word7">为展馆的数据收集、展品分布和游客接待工作调整提供准确的数据支撑，让博物馆的运作更加科学规范</div>
        <div class="main_3">
          <div class="img_6">
            <div class="img_6_1">
              <img
                v-lazy="require('./bwg_img/icon .png')"
                class="img_6_2"
              >
              <span class="txt_6">重要文物</span>
              <span class="txt_6_1"> 物品盗窃/搬移智能分析</span>
            </div>
          </div>
          <div class="img_6_3">
            <div class="img_6_1">
              <img
                v-lazy="require('./bwg_img/icon (1).png')"
                class="img_6_2"
              >
              <span class="txt_6">重要区域</span>
              <span class="txt_6_1"> 跨越警戒线、区域入侵、人员徘徊分析</span>
            </div>
          </div>
          <div class="img_6_3">
            <div class="img_6_1">
              <img
                v-lazy="require('./bwg_img/icon (2).png')"
                class="img_6_2"
              >
              <span class="txt_6">博物馆周界</span>
              <span class="txt_6_1"> 翻越围墙分析</span>
            </div>
          </div>
          <div class="img_6_3">
            <div class="img_6_1">
              <img
                v-lazy="require('./bwg_img/icon (3).png')"
                class="img_6_2"
              >
              <span class="txt_6">其他分析</span>
              <span class="txt_6_1">违章停车、烟火检测等</span>
            </div>
          </div>
        </div>
      </div>
      <!-- <div id="a9" class="a_9" v-lazy:background-image="require('./bwg_img/img 23.png')"> -->
      <div
        id="a9"
        class="a_9"
      >
        <div class="img-2">
          <img
            v-lazy='require("./bwg_img/qVWOXi4D.webp")'
            class="img-3"
          >
        </div>
        <div class="lintere4">
          <div class="info12">数字资源</div>
          <div class="word19">提供文物数字资源从制作、管理、发布、展现到价值应用一体化服务</div>
          <div class="section11 ">
            <img
              class="label1"
              v-lazy="require('./bwg_img/ICON(5).png')"
            />
            <span class="txt9">电子文档</span>
            <img
              class="pic3"
              v-lazy="require('./bwg_img/ICON(6).png')"
            />
            <span class="txt9">多媒体档案</span>
            <img
              class="pic3"
              v-lazy="require('./bwg_img/ICON(7).png')"
            />
            <span class="txt9">数字化共享平台</span>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>

</template>

<script>
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
//引入jquery
export default {
  data() {
    return {
      indexCenter1: require("./bwg_img/pic13.png"),
      indexCenter11: require("./bwg_img/pic21.png"),
      indexCenter111: require("./bwg_img/pic13.png"),
      indexCenter2: require("./bwg_img/pic5.png"),
      indexCenter22: require("./bwg_img/pic22.png"),
      indexCenter222: require("./bwg_img/pic5.png"),
      indexCenter3: require("./bwg_img/pic6.png"),
      indexCenter33: require("./bwg_img/pic20.png"),
      indexCenter333: require("./bwg_img/pic6.png"),
      indexCenter4: require("./bwg_img/pic4.png"),
      indexCenter44: require("./bwg_img/pic18.png"),
      indexCenter444: require("./bwg_img/pic4.png"),
      indexCenter5: require("./bwg_img/pic28.png"),
      indexCenter55: require("./bwg_img/pic14.png"),
      indexCenter555: require("./bwg_img/pic28.png"),
      indexCenter6: require("./bwg_img/pic1.png"),
      indexCenter66: require("./bwg_img/pic15.png"),
      indexCenter666: require("./bwg_img/pic1.png"),
      indexCenter7: require("./bwg_img/pic3.png"),
      indexCenter77: require("./bwg_img/pic17.png"),
      indexCenter777: require("./bwg_img/pic3.png"),
      indexCenter8: require("./bwg_img/pic2.png"),
      indexCenter88: require("./bwg_img/pic19.png"),
      indexCenter888: require("./bwg_img/pic2.png"),
      indexCenter9: require("./bwg_img/ICON.png"),
      indexCenter99: require("./bwg_img/img_6.png"),
      indexCenter999: require("./bwg_img/ICON.png"),
      side1_1: false,
      side2_1: false,
      side3_1: false,
      side4_1: false,
      side5_1: false,
      side6_1: false,
      side7_1: false,
      side9_1: false,
      // loading: true
    }
  },
  mounted() {
    window.addEventListener("scroll", this.sidebar2)
  }
  , components: {
    Header,
    Footer,
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.sidebar2)
  },
  methods: {
    sidebar2() {

      let scrollTop = 0;
      let distance1 = document.querySelector(".a_1")
      let distanceTop1 = distance1.offsetTop - 10
      let distance2 = document.querySelector(".a_2")
      let distanceTop2 = distance2.offsetTop - 10
      let distance3 = document.querySelector(".a_3")
      let distanceTop3 = distance3.offsetTop - 10
      let distance4 = document.querySelector(".a_4")
      let distanceTop4 = distance4.offsetTop - 10
      let distance5 = document.querySelector(".a_5")
      let distanceTop5 = distance5.offsetTop - 10
      let distance6 = document.querySelector(".a_6")
      let distanceTop6 = distance6.offsetTop - 10
      let distance7 = document.querySelector(".a_7")
      let distanceTop7 = distance7.offsetTop - 10
      let distance9 = document.querySelector(".a_9")
      let distanceTop9 = distance9.offsetTop - 20

      if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
      } else if (document.body) {
        scrollTop = document.body.scrollTop;
      }

      if (scrollTop < 1000) {
        document.querySelector(".header").style.backgroundColor = "";
      } else {
        document.querySelector(".header").style.backgroundColor = "black";
      }

      if (scrollTop < distanceTop1) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false
        this.side5_1 = false
        this.side6_1 = false
        this.side7_1 = false
        this.side9_1 = false

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "block"


      } else if (scrollTop >= distanceTop1 && scrollTop <= distanceTop2) {
        this.side1_1 = true
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false
        this.side5_1 = false
        this.side6_1 = false
        this.side7_1 = false
        this.side9_1 = false


        document.querySelector(".g1").style.backgroundColor = "#007AFF";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "none"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "block"

      }
      else if (scrollTop >= distanceTop2 && scrollTop <= distanceTop3) {
        this.side1_1 = false
        this.side2_1 = true
        this.side3_1 = false
        this.side4_1 = false
        this.side5_1 = false
        this.side6_1 = false
        this.side7_1 = false
        this.side9_1 = false

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "#007AFF";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "none"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "block"

      } else if (scrollTop >= distanceTop3 && scrollTop <= distanceTop4) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = true
        this.side4_1 = false
        this.side5_1 = false
        this.side6_1 = false
        this.side7_1 = false
        this.side9_1 = false

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "#007AFF";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "none"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "block"
      } else if (scrollTop >= distanceTop4 && scrollTop <= distanceTop5) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = true
        this.side5_1 = false
        this.side6_1 = false
        this.side7_1 = false
        this.side9_1 = false

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "#007AFF";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "none"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "block"
      }
      else if (scrollTop >= distanceTop5 && scrollTop <= distanceTop6) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false
        this.side5_1 = true
        this.side6_1 = false
        this.side7_1 = false
        this.side9_1 = false

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "#007AFF";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "none"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "block"
      }
      else if (scrollTop >= distanceTop5 && scrollTop <= distanceTop7) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false
        this.side5_1 = false
        this.side6_1 = true
        this.side7_1 = false
        this.side9_1 = false

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "#007AFF";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "none"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "block"
      }
      else if (scrollTop >= distanceTop7 && scrollTop <= distanceTop9) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false
        this.side5_1 = false
        this.side6_1 = false
        this.side7_1 = true
        this.side9_1 = false

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "#007AFF";
        document.querySelector(".g9").style.backgroundColor = "";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "none"
        document.querySelector(".side9").style.display = "block"
      } else if (scrollTop >= distanceTop9) {
        this.side1_1 = false
        this.side2_1 = false
        this.side3_1 = false
        this.side4_1 = false
        this.side5_1 = false
        this.side6_1 = false
        this.side7_1 = false
        this.side9_1 = true

        document.querySelector(".g1").style.backgroundColor = "";
        document.querySelector(".g2").style.backgroundColor = "";
        document.querySelector(".g3").style.backgroundColor = "";
        document.querySelector(".g4").style.backgroundColor = "";
        document.querySelector(".g5").style.backgroundColor = "";
        document.querySelector(".g6").style.backgroundColor = "";
        document.querySelector(".g7").style.backgroundColor = "";
        document.querySelector(".g9").style.backgroundColor = "#007AFF";

        document.querySelector(".side1").style.display = "block"
        document.querySelector(".side2").style.display = "block"
        document.querySelector(".side3").style.display = "block"
        document.querySelector(".side4").style.display = "block"
        document.querySelector(".side5").style.display = "block"
        document.querySelector(".side6").style.display = "block"
        document.querySelector(".side7").style.display = "block"
        document.querySelector(".side9").style.display = "none"
      }
    },
    changeIndex1() {
      this.indexCenter1 = this.indexCenter11
    },
    changeIndexBack1() {
      this.indexCenter1 = this.indexCenter111
    },
    changeIndex2() {
      this.indexCenter2 = this.indexCenter22
    },
    changeIndex3() {
      this.indexCenter3 = this.indexCenter33
    },
    changeIndex4() {
      this.indexCenter4 = this.indexCenter44
    },
    changeIndex5() {
      this.indexCenter5 = this.indexCenter55
    },
    changeIndex6() {
      this.indexCenter6 = this.indexCenter66
    },
    changeIndex7() {
      this.indexCenter7 = this.indexCenter77
    },
    changeIndex8() {
      this.indexCenter8 = this.indexCenter88
    },
    changeIndex9() {
      this.indexCenter9 = this.indexCenter99
    },
    changeIndexBack2() {
      this.indexCenter2 = this.indexCenter222
    },
    changeIndexBack3() {
      this.indexCenter3 = this.indexCenter333
    },
    changeIndexBack4() {
      this.indexCenter4 = this.indexCenter444
    },
    changeIndexBack5() {
      this.indexCenter5 = this.indexCenter555
    },
    changeIndexBack6() {
      this.indexCenter6 = this.indexCenter666
    },
    changeIndexBack7() {
      this.indexCenter7 = this.indexCenter777
    },
    changeIndexBack8() {
      this.indexCenter8 = this.indexCenter888
    },
    changeIndexBack9() {
      this.indexCenter9 = this.indexCenter999
    },
  }
}

</script>
<style>
.el-tooltip__popper {
  font-size: 20px !important;
}
</style>
<style lang="scss" scoped>
a {
  text-decoration: none;
}
ul {
  z-index: 999;
}
li {
  /* height: 20px;
  width: 80px; */
  list-style: none;
}
li:active {
  background-color: #fff;
}
/* 动画效果 */
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.a_1 {
  padding-top: 80.0064px;
}
.info11 {
  width: 165.0048px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 39.9936px;
  letter-spacing: 0.0065rem;
  line-height: 56.0064px;
  text-align: justify;
  align-self: center;
  margin-top: 80.0064px;
  margin: 0 auto;
  /* left: 4.5677rem; */
}
.layer4 {
  z-index: auto;
  width: 1200px;
  height: 534.0096px;
}
.word7 {
  color: rgba(86, 86, 86, 1);
  font-size: 20.0064px;
  line-height: 39.9936px;
  margin: auto;
  margin-top: 24px;
}
.txt2 {
  color: rgba(51, 51, 51, 1);
  font-size: 18.0096px;
  line-height: 24.9984px;
  display: block;
  overflow-wrap: break-word;
  text-align: justify;
  align-self: center;
  font-weight: 700;
  margin-top: 24px;
  margin-left: 18px;
}
.info1 {
  color: rgba(51, 51, 51, 1);
  font-size: 18.0096px;
  line-height: 24.9984px;
  display: block;
  overflow-wrap: break-word;
  text-align: justify;
  align-self: center;
  font-weight: 700;
  margin-top: 24px;
  margin-left: 18px;
}
.ml {
  margin-left: 30px;
}
.img_2_2 {
  width: 380px;
  height: 260px;
}

.word8 {
  width: 345px;
  height: 40px;
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
  line-height: 20.0064px;
  margin-top: 15.9936px;
  display: block;
  overflow-wrap: break-word;
  text-align: justify;
  align-self: center;
  margin-left: 18px;
}
.img_1 {
  z-index: auto;
  width: 380px;
  height: 433.9968px;
  box-shadow: 1.9968px 3.9936px 12px 1.9968px rgba(0, 0, 0, 0.04);
}
.load-img {
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 50px;
    height: 50px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 380px;
    height: 260px;
  }
}
.main {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.txt3 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(0, 122, 255, 1);
  font-size: 15.9936px;
  letter-spacing: 0.4992px;
  line-height: 22.0032px;
  text-align: left;
  align-self: flex-start;
  margin-top: 24px;
  margin-left: 18px;
  cursor: pointer;
}
.img_2 {
  width: 380.0064px;
  height: 433.9968px;
  box-shadow: 1.9968px 3.9936px 12px 1.9968px rgba(0, 0, 0, 0.04);
}
.img_3 {
  width: 380.0064px;
  height: 433.9968px;
  box-shadow: 1.9968px 3.9936px 12px 1.9968px rgba(0, 0, 0, 0.04);
}
.word11 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(204, 204, 204, 1);
  font-size: 15.9936px;
  letter-spacing: 0.4992px;
  line-height: 22.0032px;
  text-align: left;
  align-self: flex-start;
  margin-top: 24px;
  margin-left: 18px;
}
.a_2 {
  height: 720px;
  //  background-repeat: no-repeat;
  // background-size: 100% 100%;
  margin: auto;
  margin-top: 100px;
  position: relative;
}
.img-3 {
  height: 720px;
}
.img-2 {
  height: 720px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 720px;
    width: 100%;
  }
}
.lintere2 {
  position: absolute;
  margin: auto;
  top: 252px;
  left: 398px;
}
.lintere3 {
  position: absolute;
  top: 252px;
  left: 624px;
}
.lintere4 {
  position: absolute;
  margin: auto;
  top: 252px;
  left: 410px;
}
.lintere8 {
  position: absolute;
  margin: auto;
  top: 252px;
  left: 455px;
}
.info12 {
  width: 165.0048px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 39.9936px;
  letter-spacing: 1.248px;
  line-height: 56.0064px;
  text-align: justify;
  align-self: center;
  // padding-top: 80.0064px;
  margin: 0 auto;
  // padding-top: 252px;
}
.info4 {
  width: 330.0096px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 39.9936px;
  letter-spacing: 1.248px;
  line-height: 56.0064px;
  text-align: justify;
  align-self: center;
  margin-top: 80.0064px;
  margin: 0 auto;
  // padding-top: 252px;
}
.word4 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 20.0064px;
  line-height: 39.9936px;
}
.word20 {
  display: block;
  overflow-wrap: break-word;
  color: #007aff;
  font-size: 20.0064px;
  line-height: 39.9936px;
}
.txt4 {
  width: 155.0016px;
  height: 48px;
  border-radius: 24px;
  border: 0.9984px solid rgba(204, 204, 204, 1);
  margin: auto;
  margin-top: 36px;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.txt20 {
  width: 155.0016px;
  height: 48px;
  border-radius: 24px;
  border: 0.9984px solid #007aff;
  margin: auto;
  margin-top: 36px;
  line-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.word19 {
  color: rgba(255, 255, 255, 1);
  font-size: 20.0064px;
  letter-spacing: 0.9984px;
  line-height: 39.9936px;
  margin: auto;
  margin-top: 24px;
}
.section10 {
  width: 1113.0048px;
  height: 56.0064px;
  margin: auto;
  margin-top: 20.0064px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.label1 {
  width: 56.0064px;
  margin-top: 9.0048px;
}
.txt10 {
  color: rgba(255, 255, 255, 1);
  font-size: 20.0064px;
  margin-left: 13.9968px;
}
.pic2 {
  width: 56.0064px;
  margin-top: 9.0048px;
  margin-left: 75.9936px;
}
.info13 {
  color: rgba(51, 51, 51, 1);
  font-size: 39.9936px;
  letter-spacing: 1.248px;
  padding-top: 80.0064px;
}
.a_min_3 {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.a_min_3_3 {
  width: 585.0048px;
  height: 420px;
}
.load-2 {
  width: 585.0048px;
  height: 420px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 70px;
    height: 70px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 585.0048px;
    height: 420px;
  }
}
.a_min_3_4 {
  width: 585.0048px;
  height: 420px;
  margin-left: 30.0096px;
}
.a_4 {
  height: 720px;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  margin: auto;
  margin-top: 99.9936px;
  position: relative;
}
.info14 {
  width: 165.0048px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 39.9936px;
  line-height: 56.0064px;
  padding-top: 80.0064px;
  text-align: justify;
  align-self: center;
  margin: 0 auto;
}
.word10 {
  color: rgba(86, 86, 86, 1);
  font-size: 20.0064px;
  line-height: 39.9936px;
  margin: 0 auto;
  margin-top: 24px;
  margin-left: -10.0032px;
}
.main_2 {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}
.txt_5 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 18.0096px;
  line-height: 24.9984px;
  margin: auto;
  margin-top: 24px;
}
.txt_5_5 {
  width: 229.9968px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 13.9968px;
  letter-spacing: 0.5833333134651184px;
  line-height: 20.0064px;
  margin: auto;
  margin-top: 15.9936px;
}
.img_5 {
  height: 376.992px;
  border-radius: 6.0096px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1.9968px 3.9936px 12px 1.9968px rgba(0, 0, 0, 0.04);
  width: 277.9968px;
}
.img_5_1 {
  height: 376.992px;
  border-radius: 6.0096px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1.9968px 3.9936px 12px 1.9968px rgba(0, 0, 0, 0.04);
  width: 277.9968px;
  margin-left: 30.0096px;
}
.a_6 {
  height: 720px;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  margin: auto;
  margin-top: 99.9936px;
  position: relative;
}
.main_3 {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
}
.img_6 {
  height: 291.9936px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1.9968px 3.9936px 12px 1.9968px rgba(0, 0, 0, 0.04);
  width: 277.9968px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}
.img_6_3 {
  height: 291.9936px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 1.9968px 3.9936px 12px 1.9968px rgba(0, 0, 0, 0.04);
  width: 277.9968px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  margin-left: 30.0096px;
}
.img_6_2 {
  width: 92.0064px;
  height: 92.0064px;
  margin-top: 40px;
}

.txt_6 {
  display: block;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20.0064px;
  letter-spacing: 0.8256px;
  line-height: 27.9936px;
  margin: auto;
  margin-top: 2.9952px;
  font-weight: 700;
}
.txt_6_1 {
  width: 205.9968px;
  display: block;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 13.9968px;
  line-height: 20.0064px;
  margin: auto;
  margin-top: 15.9936px;
}
.a_9 {
  margin-top: 99.9936px;
  height: 720px;
  // background-repeat:  no-repeat;
  // background-size: 100% 100%;
  margin: auto;
  margin-bottom: 0;
  margin-top: 99.9936px;
  position: relative;
}
.txt9 {
  color: rgba(255, 255, 255, 1);
  font-size: 20.0064px;
  letter-spacing: 0.8256px;
  margin-left: 24px;
}
.pic3 {
  width: 56.0064px;
  margin-top: 9.0048px;
  margin-left: 164.0064px;
}
.section11 {
  width: 1113.0048px;
  height: 56.0064px;
  margin: auto;
  margin-top: 44.0064px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.pic6 {
  width: 278px;
  height: 208px;
}
.img-5 {
  width: 278px;
  height: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 50px;
    height: 50px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    width: 278px;
    height: 208px;
  }
}
.headers {
  color: rgba(255, 255, 255, 1);
  font-size: 48px;
  // letter-spacing: 3.4285714626312256px;
  line-height: 67px;
  text-align: justify;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  // padding-left: 360px;
  // padding-top: 230px;
}
.headers {
  animation: sport 0.5s;
  transform: translateY(0px);
}
.header2 {
  color: rgba(255, 255, 255, 1);
  font-size: 24px;
  line-height: 33px;
  text-align: justify;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  align-self: center;
  margin-top: 20px;
  width: 700px;
  // padding-left: 360px;
}
.header2 {
  animation: sport 0.5s;
  transform: translateY(0px);
}
.a_8 {
  height: 600px;
  width: 100%;
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
}
.start_img {
  height: 600px;
  width: 100%;
}
.start {
  height: 600px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  img[lazy="loading"] {
    width: 200px;
    height: 200px;
  }
  // 实际图片大小
  img[lazy="loaded"] {
    height: 600px;
    width: 100%;
  }
}
.bannert {
  position: absolute;
  left: 361px;
  top: 230px;
}
ul {
  position: fixed;
  right: 0;
  top: 95px;
}
li {
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.side {
  height: 32px;
  height: 32px;
}
</style>